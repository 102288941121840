import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { MenuComponent } from './theme/components/menu/menu.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppSettings }  from './app.settings';
import { LinkerModule, LinkerService } from "@clavisco/linker";
import { OverlayModule, OverlayService } from "@clavisco/overlay";
import { AlertsModule, AlertsService } from "@clavisco/alerts";
import { APP_ROUTING } from './app.routes';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthService} from "./core/services/auth.service";
import { AuthGuard } from "./core/guards/auth.guard";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MenuModule } from "@clavisco/menu";
import { ReactiveFormsModule } from "@angular/forms";
import {Miscellaneous} from "@clavisco/core";
import {HeadersInterceptor} from "./core/interceptors/headers.interceptor";
import {UrlInterceptor} from "./core/interceptors/url.interceptor";
import {ErrorInterceptor} from "./core/interceptors/error.interceptor";
import {MatTabsModule} from "@angular/material/tabs";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';


@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    MenuComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    LinkerModule,
    OverlayModule,
    AlertsModule,
    APP_ROUTING,
    HttpClientModule,
    MatToolbarModule,
    MenuModule,
    MatIconModule,
    MatSidenavModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatTabsModule
  ],
  providers: [AppSettings,
    AuthService,
    AuthGuard,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: 'LinkerService',
      useClass: LinkerService
    },
    {
      provide: 'AlertsService',
      useClass: AlertsService
    },
    {
      provide: 'OverlayService',
      useClass: OverlayService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Miscellaneous.Interceptors.HttpAlertInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: "6LdbkzcqAAAAAFZN9EbgEWRtX5gcptqmlHUDyeqw"
    },
  ],
  exports: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
