import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {IGroup} from "../interfaces/i-group";


@Injectable({
  providedIn: 'root'
})
export class TabComponentService {

  private dataSubject = new BehaviorSubject<any>(null);
  data = this.dataSubject.asObservable();
  private groupDetailsTabDisabled = new BehaviorSubject<boolean>(true);
  groupDetailTabDisabled = this.groupDetailsTabDisabled.asObservable();
  private selectedTabIndexSubject = new BehaviorSubject<number>(0);
  selectedTabIndex = this.selectedTabIndexSubject.asObservable();

  constructor() { }

  sendData(Group: IGroup){
    this.dataSubject.next(Group);
  }

  enableTab(enable: boolean, selectedTabIndex: number){
    this.groupDetailsTabDisabled.next(enable);
    this.selectedTabIndexSubject.next(selectedTabIndex);
  }

  clearData(): void{
    this.dataSubject.next(null);
    this.groupDetailsTabDisabled.next(true);
    this.selectedTabIndexSubject.next( 0);
  }

  changeTab(idxTabSelected: number) {
    let x = this.selectedTabIndexSubject.value;
    if (x !== idxTabSelected)
      this.selectedTabIndexSubject.next(idxTabSelected);
  }

}
