import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import { environment } from 'src/environments/environment';
import {Repository, Structures} from "@clavisco/core";
import {IToastGlobalConfiguration} from "@clavisco/alerts";
import {AuthService} from "./core/services/auth.service";
import {LinkerService} from "@clavisco/linker";
import {Login} from "@clavisco/login";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  loading: boolean = false;
  public settings: Settings;
  currentUser: any;
  constructor(public appSettings: AppSettings,
              @Inject('LinkerService') private linkerService: LinkerService,
              public router: Router,
              private authService: AuthService,
              @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.settings = this.appSettings.settings;
    this.authService.currentUserSubject.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    Repository.Behavior.SetTokenConfiguration({
      token: 'Shared',
      setting: 'apiUrl',
      value: environment.ApiUrl
    });

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'changePassword',
      value: {
        endpointToRequest: `${environment.ApiUrl}Users/ChangePassword`
      } as Login.Structures.IChangePasswordConfig
    })

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'recoverPassword',
      value: {
        endpointToRequest: `${environment.ApiUrl}Users/ChangeRecoveryPassword`
      }  as Login.Structures.IChangePasswordConfig
    })

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'sendRecoverPasswordEmail',
      value: {
        urlVariableToReplaceWithEmail: '#EMAIL#',
        endpointToRequest: `${environment.ApiUrl}Users/#EMAIL#/RecoveryPassword`
      }
    })

  }


  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
      }
    })
  }
}
