import { IMenuNode } from "@clavisco/menu";

const Menu: IMenuNode[] = [
  {
    Key: "home",
    Description: "Inicio",
    Icon: "home",
    Route: "/home",
    Visible: true,
    Nodes: []
  },
  {
    Key: "M_Users",
    Description: "Usuarios",
    Icon: "person",
    Route: "/users",
    Visible: true,
    Nodes: []
  },
  {
    Key: "M_Roles",
    Description: "Roles",
    Icon: "engineering",
    Route: "/roles",
    Visible: true,
    Nodes: []
  },
  {
    Key: "M_AssignPerm",
    Description: "Asignación de Permisos",
    Icon: "assignment",
    Route: "/permissions",
    Visible: true,
    Nodes: []
  },
  {
    Key: "M_Groups",
    Description: "Grupos",
    Icon: "groups",
    Route: "/groups",
    Visible: true,
    Nodes: []
  },
  {
    Key: "M_MailConfig",
    Description: "Configuración de Correos",
    Icon: "settings",
    Route: "/mailConfig",
    Visible: true,
    Nodes: []
  },
  {
    Key: "M_Routines",
    Description: "Rutinas",
    Icon: "move_down",
    Route: "/routines",
    Visible: true,
    Nodes: []
  },
  {
    Key: "logout",
    Description: "Cerrar sesión",
    Icon: "logout",
    Route: "",
    Visible: true,
    Nodes: []
  }
]

export default Menu;
