import { Injectable } from '@angular/core';
import {StorageService} from "./storage.service";
import {IMenuNode} from "@clavisco/menu";
import {Subject} from "rxjs";
import {formatDate} from "@angular/common";


@Injectable({
  providedIn: 'root'
})
export class GlobalFunctionsService {

  private dataUpdatedSource = new Subject<void>();
  dataUpdated$ = this.dataUpdatedSource.asObservable();
  constructor(private storageService: StorageService) { }

  public CheckMenuPermissions(permissionList: string[], listMenu: IMenuNode[]): IMenuNode[] {
    const menuNodes: IMenuNode[] = [];
    //#se maneja el nombre del permiso igual al key del menú de clavis#
    listMenu.filter((element) =>  permissionList?.findIndex(item => item===element.Key)>-1  ||
      element.Key == "home" || element.Key == "logout")
      .forEach((element) => {
        const filteredNodes = element.Nodes ? this.CheckMenuPermissions(permissionList, element.Nodes) : [];
        if (filteredNodes.length > 0) {
          element.Nodes = filteredNodes;
        }
        menuNodes.push(element);
      });
    return menuNodes;
  }


  public CheckPermission(permissionName: string): boolean {

    let permissionList: string[] = JSON.parse(this.storageService.getItem('permissions'));

    if (permissionList && permissionList.length) {
      return permissionList.some((permission) => permission === permissionName);
    } else {
      return false;
    }
  }

  triggerDataUpdate() {
    this.dataUpdatedSource.next();
  }

  FormatDate(_date: string | Date): string {
    if (_date) {
      return formatDate(_date, 'yyyy-MM-dd', 'en');
    } else {
      return formatDate(new Date(), 'yyyy-MM-dd', 'en');
    }
  }

}
