import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpStatusCode
} from '@angular/common/http';
import {catchError, Observable, tap, throwError} from 'rxjs';
import {CLModalType, ModalService} from '@clavisco/alerts';
import { environment } from "../../../environments/environment";
import {AuthService} from "../services/auth.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
              private modalService: ModalService,
              private sessionService: AuthService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((error: any) => {
      if(error['status'] === 0){
        this.modalService.Continue({
          title: 'Sin conexion con el servidor',
          type: CLModalType.ERROR
        }).subscribe();
      }
      else if(error['status'] === HttpStatusCode.Conflict){
        this.modalService.Continue({
          title: 'Error',
          subtitle: error['Message'],
          type: CLModalType.ERROR
        }).subscribe();
      }
      else if (error['status'] === HttpStatusCode.Unauthorized && error['url'].includes(environment.ApiUrl)) {
        this.modalService.Continue({
          title: 'Token de autorización no válido',
          type: CLModalType.INFO
        }).subscribe();

        this.sessionService.logout();
      }
      else if (error['status'] === HttpStatusCode.InternalServerError) {
        this.modalService.Continue({
          title: 'Error interno del servidor',
          subtitle: error['error'].Message??error['error'],
          type: CLModalType.ERROR
        }).subscribe();
      }
      else if (error['status'] === HttpStatusCode.Forbidden) {
        this.modalService.Continue({
          title: 'No tiene permisos para realizar esta acción',
          type: CLModalType.ERROR
        }).subscribe();
        return throwError(()=> {});
      }

      return throwError(()=> {
        if(error?.error?.code === HttpStatusCode.Unauthorized){
          return error['message'] = error?.error?.message ?? ''
        }
        return error
      });
    }));
  }

  // intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  //   return next.handle(request).pipe(
  //     catchError((res: any) => {
  //       if (res['status'] === HttpStatusCode.Unauthorized && res['url'].includes(environment.ApiUrl)) {
  //         this.modalService.Continue({
  //           title: 'Token de autorización no válido',
  //           type: CLModalType.INFO
  //         }).subscribe();
  //         this.authService.logout();
  //       }
  //       return throwError(() => res);
  //     }));
  // }

}
