import {Component, Inject, OnInit, OnChanges, ChangeDetectorRef, AfterContentInit} from '@angular/core';
import { AuthService } from "../../../core/services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import { CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown } from "@clavisco/linker";
import { StorageService } from "../../../core/services/storage.service";
import { IMenuNode } from "@clavisco/menu";
import Menu from "../../../../assets/data/menu";
import {GlobalFunctionsService} from "../../../core/services/global-functions.service";
import {AlertsService, CLToastType} from "@clavisco/alerts";
import {PermissionsService} from "../../../core/services/permissions.service";
import {OverlayService} from "@clavisco/overlay";



@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterContentInit {

  MenuId: string = 'menuId';
  Logo: string = '/assets/Logo-Clavisco.png';
  Title: string = 'Replicador de Datos Maestros y Cuentas Contables';
  User: string =  ''
  Toggle: Boolean = true;
  Nodes: IMenuNode[] = [];

  listStoragePermissions: string[] = [];
  currentRole: string = '';

  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: []
  };
  constructor(private authService: AuthService,
              private router: Router,
              @Inject('LinkerService') private linkerService: LinkerService,
              private storageService: StorageService,
              private globalFunctionsService: GlobalFunctionsService,
              private permissionService: PermissionsService,
              private alertService: AlertsService,
              private overlayService: OverlayService,
              private activatedRoute: ActivatedRoute,
              private cdRef: ChangeDetectorRef,
              ) {
    if(this.storageService.getItem('Session'))
      this.currentRole = JSON.parse(atob(this.storageService.getItem('Session')!)).RoleId;
  }

  ngOnInit(): void {
    if(this.storageService.getItem('Session')){
      this.User = JSON.parse(atob(this.storageService.getItem('Session')!)).userName;
    }

    this.GetPermissionsByRole(Number(this.currentRole))

    Register(this.MenuId, CL_CHANNEL.OUTPUT, this.ClickMenuOption, this.callbacks);

    this.linkerService.Flow()?.pipe(
      StepDown<CL_CHANNEL>(this.callbacks),
    ).subscribe({
      next: callback => Run(callback.Target, callback, this.callbacks.Callbacks),
      error: error => console.log(`Error:`, error)
    });

    this.listStoragePermissions = JSON.parse(this.storageService.getItem('permissions'));
    this.Nodes = this.globalFunctionsService.CheckMenuPermissions(this.listStoragePermissions, Menu);

    this.authService.currentPermissionsSubject.subscribe((data) => {
      // Aquí puedes refrescar la lista en ComponenteA
      if (data)
      {
        this.Nodes = this.globalFunctionsService.CheckMenuPermissions(this.listStoragePermissions, Menu);
        this.linkerService.Publish(
          {
            CallBack: CL_CHANNEL.INFLATE,
            Data: JSON.stringify(this.Nodes),
            Target: this.MenuId
          }
        )
      }
    });
  }

  ngAfterContentInit(): void {
    this.cdRef.detectChanges();
  }
  public GetPermissionsByRole(roleId: number): void {
      this.permissionService.GetPermissionsByRole(roleId).subscribe(
         (data) => {
            if (data) {
              this.listStoragePermissions = data.Data.map(item => item.Name);
              this.storageService.setItem('permissions', this.listStoragePermissions);
              this.authService.currentPermissionsSubject.next(this.listStoragePermissions);
            } else {
              this.alertService.Toast({ message:'', type: CLToastType.WARNING });
            }
          })
  }

  ClickMenuOption = (_event: ICLEvent): void => {
    const menuNode: IMenuNode = JSON.parse(_event.Data) as IMenuNode;
    switch (menuNode.Key) {
      case "logout":
        this.authService.logout();
    }
  }

  ToggleMenu(): void {
    this.Toggle = !this.Toggle;
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.DATA_LINE_1,
      Target: this.MenuId,
      Data: JSON.stringify(this.Toggle)
    } as ICLEvent);
  }
}
