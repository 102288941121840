<cl-menu [Id]="MenuId"
         [Logo]="Logo"
         [Title]="Title"
         [User]="User"
         [Nodes]="Nodes">
  <div class="exclude space-r">
    <mat-toolbar>
      <button mat-icon-button (click)="ToggleMenu()">
        <mat-icon>menu</mat-icon>
      </button>
    </mat-toolbar>
  </div>
  <router-outlet class="exclude"></router-outlet>
</cl-menu>
