import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "./core/guards/auth.guard";
import { PagesComponent } from "./pages/pages.component";
import {VerifyPermissionsGuard} from "./core/guards/verify-permissions.guard";

export const APP_ROUTES: Routes = [

  {
    path: '',
    component: PagesComponent, children: [
      { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard]},
      { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard]},
      { path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),  canActivate: [AuthGuard, VerifyPermissionsGuard] },
      { path: 'roles', loadChildren: () => import('./pages/roles/roles.module').then(m => m.RolesModule),  canActivate: [AuthGuard, VerifyPermissionsGuard] },
      { path: 'permissions', loadChildren: () => import('./pages/permissions/permissions.module').then(m => m.PermissionsModule),  canActivate: [AuthGuard, VerifyPermissionsGuard] },
      { path: 'groups', loadChildren: () => import('./pages/group-config/group-config.module').then(m => m.GroupConfigModule),  canActivate: [AuthGuard, VerifyPermissionsGuard] },
      { path: 'mailConfig', loadChildren: () => import('./pages/mail-config/mail-config.module').then(m => m.MailConfigModule),  canActivate: [AuthGuard] },
      { path: 'routines', loadChildren: () => import('./pages/routines/routines.module').then(m => m.RoutinesModule),  canActivate: [AuthGuard] }
    ]
  },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: '**', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] }
]

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { useHash: true});
