import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {FlexLayoutModule} from "@angular/flex-layout";
import {LoginModule} from "@clavisco/login";
import {RouterModule} from "@angular/router";
import {MatFormFieldModule} from '@angular/material/form-field';
import {TableModule} from "@clavisco/table";
import {SkeletonModule} from "@clavisco/skeleton";
import {MatSelectModule} from "@angular/material/select";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatMenuModule} from "@angular/material/menu";
import {MenuModule} from "@clavisco/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatDialogModule} from "@angular/material/dialog";
import {MatRadioModule} from "@angular/material/radio";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatTabsModule} from "@angular/material/tabs";
import {MatDividerModule} from '@angular/material/divider';
import {MatDatepickerModule} from "@angular/material/datepicker";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    FlexLayoutModule,
    MatMenuModule,
    MenuModule,
    MatToolbarModule,
    MatButtonModule,
    LoginModule,
    MatFormFieldModule,
    TableModule,
    SkeletonModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDialogModule,
    MatRadioModule,
    MatCheckboxModule,
    DragDropModule,
    MatTabsModule,
    MatIconModule,
    MatDividerModule,
    MatDatepickerModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    FlexLayoutModule,
    LoginModule,
    MatMenuModule,
    MenuModule,
    MatToolbarModule,
    MatButtonModule,
    LoginModule,
    MatFormFieldModule,
    TableModule,
    SkeletonModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDialogModule,
    MatRadioModule,
    MatCheckboxModule,
    DragDropModule,
    MatIconModule,
    MatTabsModule,
    MatDividerModule,
    MatDatepickerModule
  ]
})
export class SharedModule { }
