import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export class Settings {
  constructor(public name: string,
              public theme: string,
              public rtl: boolean,
              public mainToolbarFixed: boolean) { }
}

@Injectable()
export class AppSettings {
  public settings = new Settings(
    'app',  // theme name
    `${environment.Theme}`,     // custom color
    false,       // true = rtl, false = ltr
    false        // mainToolbarFixed
  )
}
