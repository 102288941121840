import { Component, OnInit } from '@angular/core';
import {AlertsService, CLToastType} from "@clavisco/alerts";
import {IPermission} from "../core/interfaces/i-permission";
import {StorageService} from "../core/services/storage.service";
import {AuthService} from "../core/services/auth.service";
import {PermissionsService} from "../core/services/permissions.service";
import {GlobalFunctionsService} from "../core/services/global-functions.service";
import {PermissionsByRoleResponse} from "../core/interfaces/response-model";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
