import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {Repository} from "@clavisco/core";
import {IPermission} from "../interfaces/i-permission";
import {TabComponentService} from "./tab-component.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public currentUserSubject: BehaviorSubject<any>;
  public currentPermissionsSubject!: BehaviorSubject<any>;
  constructor(private tabComponentService: TabComponentService, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<any>(Repository.Behavior.GetStorageObject('Session'));
    this.currentPermissionsSubject = new BehaviorSubject<any>(Repository.Behavior.GetStorageObject('permissions'));
  }

  logout() {
    localStorage.removeItem('Session');
    localStorage.removeItem('permissions');
    this.currentUserSubject.next(null);
    //this.tabComponentService.clearData();
    this.currentPermissionsSubject.next(null);
    this.router.navigateByUrl('/login');
  }
}
