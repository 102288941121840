import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalFunctionsService } from "../services/global-functions.service";
import { AlertsService, CLToastType } from "@clavisco/alerts";

@Injectable({
  providedIn: 'root'
})
export class VerifyPermissionsGuard implements CanActivate {

  constructor(private globalFunctionsService: GlobalFunctionsService,
              private alertService: AlertsService) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let requiredPermissions: string = this.getRequiredPermission(state.url);
    let havePermission: boolean = this.globalFunctionsService.CheckPermission(requiredPermissions);
    if (!havePermission) {
      this.alertService.Toast({ message: 'Acceso denegado, no posee permisos para realizar esta acción', type: CLToastType.ERROR })
    }
    return havePermission;
  }

  getRequiredPermission(route: string) {
    //Permiso requerido para visualizar cada opción en el menú
    let requiredPermission: string = '';

    switch (route) {
      case '/users':
        requiredPermission = "M_Users";
        break;

      case '/roles':
        requiredPermission = "M_Roles";
        break;

      case '/permissions':
        requiredPermission = "M_AssignPerm";
        break;

      case '/groups':
        requiredPermission = "M_Groups";
        break;

      case '/mailConfig':
        requiredPermission = "M_MailConfig";
        break;

      case '/routines':
        requiredPermission = "M_Routines";
        break;
    }
    return requiredPermission;
  }
}
